import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime"

import { SITE_URLS } from "@common/constants/index"
import { assertUserIdPresence, getUser, getUserId } from "@common/utils/auth"
import { clearAuthenticationDetails } from "@common/utils/auth/sso"
import { getApiServerBaseUrl } from "@common/utils/env"
import {
	ApiRequest,
	getAPIHeaders,
	getAPIHeadersWithSource,
} from "@common/utils/service"
import { getLogoutApiUrl } from "@common/utils/service/apiUrls"

/**
 * Logs out the user by making an API request to delete the user's session.
 */
export const logoutUserAPI = async (): Promise<any> => {
	const user = getUser()

	const requestParams = {
		userId: user.userId,
	}
	const headers = getAPIHeadersWithSource()

	try {
		assertUserIdPresence(user.userId)

		const data = await ApiRequest.delete(
			getLogoutApiUrl(),
			requestParams,
			headers,
		)
		await clearAuthenticationDetails()
		return data
	} catch (error) {
		console.error(error)
	}

	return null
}

export const triggerUserLogout = async (router: AppRouterInstance) => {
	await logoutUserAPI()

	router.replace(SITE_URLS.LOGOUT)
}

export const getUnitySavingAccountDetailsAPI = (pin: string) => {
	const headers = getAPIHeaders(true, true)

	return ApiRequest.put(
		`${getApiServerBaseUrl()}/fd/unity/savings-account?userId=${getUserId()}`,
		{
			pin,
		},
		headers,
	)
}
